import {useForm} from 'react-hook-form';
import {Fragment, ReactNode, useState, useRef, useEffect} from 'react';
import {LoginOTPPayload, useLogin} from '../../requests/use-Otp-login';
import {Form} from '../../../ui/forms/form';
import {FormTextField} from '../../../ui/forms/input-field/text-field/text-field';

export function OtpAuthSection() {
  const textInput = useRef<HTMLInputElement>(null);
  const [focusData, setFocusData] = useState('OTPfirst');
  const [formData, setFormData] = useState({
    OTPfirst: '',
    OTPsecond: '',
    OTPthird: '',
    OTPfourth: '',
  });

  useEffect(() => {
    document
      .getElementById('OTPfirst')
      ?.classList.replace('text-left', 'text-center');
    document
      .getElementById('OTPsecond')
      ?.classList.replace('text-left', 'text-center');
    document
      .getElementById('OTPthird')
      ?.classList.replace('text-left', 'text-center');
    document
      .getElementById('OTPfourth')
      ?.classList.replace('text-left', 'text-center');

    if (
      document
        .getElementById('otp_container')
        ?.parentElement?.className.includes('pointer-events-none')
    ) {
      document
        .getElementById('otp_container')
        ?.parentElement?.classList.remove('pointer-events-none');
    }
    // if((formData.OTPfirst !=='' || formData.OTPfirst =='') || (formData.OTPsecond !=='' || formData.OTPsecond =='') || (formData.OTPthird !=='' || formData.OTPthird =='') || (formData.OTPfourth !=='' || formData.OTPfourth =='')){
    let classData = textInput.current?.className;
    let classDataArray = classData?.split(' ');
    let classDataFilter = '';
    classDataArray?.filter(item => {
      if (item == focusData) {
        return (classDataFilter = item);
      }
    });
    document.getElementById(`${focusData}`)?.focus();
    setFocusData('');
    // }
  }, [formData]);

  const form = useForm<LoginOTPPayload>({
    defaultValues: {
      OTPfirst: formData.OTPfirst,
      OTPsecond: formData.OTPsecond,
      OTPthird: formData.OTPthird,
      OTPfourth: formData.OTPfourth,
    },
  });

  const login = useLogin(form);

  const isInvalid = !form.formState.isValid;

  const handleInput = (
    event: React.ChangeEvent<HTMLInputElement>
    // param: string
  ) => {
    let fieldValue = event?.target.value;
    let fieldNum = parseInt(fieldValue);
    let valueCheck = isNaN(fieldNum);
    if (!valueCheck && fieldValue !== '' && event?.target.name == 'OTPfirst') {
      setFormData(preveState => ({
        ...preveState,
        OTPfirst: fieldValue,
      }));
      setFocusData('OTPsecond');
    } else if (
      !valueCheck &&
      fieldValue !== '' &&
      event?.target.name == 'OTPsecond'
    ) {
      setFormData(preveState => ({
        ...preveState,
        OTPsecond: fieldValue,
      }));
      setFocusData('OTPthird');
    } else if (
      !valueCheck &&
      fieldValue !== '' &&
      event?.target.name == 'OTPthird'
    ) {
      setFormData(preveState => ({
        ...preveState,
        OTPthird: fieldValue,
      }));
      setFocusData('OTPfourth');
    } else if (
      !valueCheck &&
      fieldValue !== '' &&
      event?.target.name == 'OTPfourth'
    ) {
      setFormData(preveState => ({
        ...preveState,
        OTPfourth: fieldValue,
      }));
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    param: string
  ) => {
    if (event.code == 'Delete' || event.code == 'Backspace') {
      if (param == 'OTPfirst') {
        setFormData(preveState => ({
          ...preveState,
          OTPfirst: '',
        }));
      } else if (param == 'OTPsecond') {
        setFormData(preveState => ({
          ...preveState,
          OTPsecond: '',
        }));
        setFocusData('OTPfirst');
      } else if (param == 'OTPthird') {
        setFormData(preveState => ({
          ...preveState,
          OTPthird: '',
        }));
        setFocusData('OTPsecond');
      } else if (param == 'OTPfourth') {
        Promise.resolve()
          .then(() => {
            setFormData(preveState => ({
              ...preveState,
              OTPfourth: '',
            }));
          })
          .then(() => {
            setFocusData('OTPthird');
          });
      }
    }
  };

  return (
    <Fragment>
      <div
        className="otp_container  rounded-lg max-w-350 px-40 pt-40 pb-32 w-full mx-auto bg-paper shadow md:shadow-xl"
        id="otp_container"
      >
        <h2 className="mb-20 text-xl text-center">Enter OTP</h2>
        <Form
          className="otp_flexBox flex mb-30 flex-wrap"
          form={form}
          onSubmit={payload => {
            login.mutate(payload);
          }}
        >
          <FormTextField
            className="mr-20 w-50 block text-center relative block text-center relative OTPfirst"
            id="OTPfirst"
            name="OTPfirst"
            type="password"
            maxLength={1}
            onChange={event => handleInput(event)}
            onKeyDown={event => handleKeyDown(event, 'OTPfirst')}
            ref={textInput}
            required
          />
          <FormTextField
            className="mr-20 w-50 block text-center relative block text-center relative OTPsecond"
            name="OTPsecond"
            id="OTPsecond"
            type="password"
            maxLength={1}
            onChange={event => handleInput(event)}
            onKeyDown={event => handleKeyDown(event, 'OTPsecond')}
            ref={textInput}
            required
          />
          <FormTextField
            className="mr-20 w-50 block text-center relative block text-center relative OTPthird"
            name="OTPthird"
            id="OTPthird"
            type="password"
            maxLength={1}
            onChange={event => handleInput(event)}
            onKeyDown={event => handleKeyDown(event, 'OTPthird')}
            ref={textInput}
            required
          />
          <FormTextField
            className="w-50 block text-center relative block text-center relative OTPfourth"
            name="OTPfourth"
            id="OTPfourth"
            type="password"
            maxLength={1}
            onChange={event => handleInput(event)}
            onKeyDown={event => handleKeyDown(event, 'OTPfourth')}
            ref={textInput}
            required
          />

          <button
            type="submit"
            className="mt-30 focus-visible:ring text-on-primary bg-primary border border-primary hover:bg-primary-dark hover:border-primary-dark disabled:text-disabled disabled:bg-disabled disabled:border-transparent disabled:shadow-none whitespace-nowrap align-middle inline-flex items-center transition-button duration-200 select-none appearance-none no-underline outline-none disabled:pointer-events-none disabled:cursor-default rounded justify-center font-semibold text-base h-42 px-22 block w-full"
            disabled={
              formData.OTPfirst !== '' &&
              formData.OTPsecond !== '' &&
              formData.OTPthird !== '' &&
              formData.OTPfourth !== ''
                ? false
                : true
            }
          >
            Submit
          </button>
        </Form>
      </div>
    </Fragment>
  );
}
