import {useMutation} from '@tanstack/react-query';
import {UseFormReturn} from 'react-hook-form';
import {BackendResponse} from '../../http/backend-response/backend-response';
import {BackendErrorResponse} from '../../errors/backend-error-response';
import {onFormQueryError} from '../../errors/on-form-query-error';
import {useNavigate} from '../../utils/hooks/use-navigate';
import {apiClient} from '../../http/query-client';
import {useAuth} from '../use-auth';
import {useBootstrapData} from '../../core/bootstrap-data/bootstrap-data-context';

interface Response extends BackendResponse {
  bootstrapData: string;
}

export interface LoginOTPPayload {
  OTPfirst: string;
  OTPsecond: string;
  OTPthird: string;
  OTPfourth: string;
}

export function useLogin(form: UseFormReturn<LoginOTPPayload>) {
  const navigate = useNavigate();
  const {getRedirectUri} = useAuth();
  const {setBootstrapData} = useBootstrapData();
  return useMutation(login, {
    onSuccess: response => {
      setBootstrapData(response.bootstrapData);
      navigate(getRedirectUri(), {replace: true});
      window.localStorage.removeItem('email');
      window.localStorage.removeItem('password');
      window.location.reload();
    },
    onError: r => {
      onFormQueryError(r, form);
    },
  });
}

function login(payload: LoginOTPPayload): Promise<Response> {
  let payloadArray = [
    payload.OTPfirst,
    payload.OTPsecond,
    payload.OTPthird,
    payload.OTPfourth,
  ];
  let payloadArrayJoin = payloadArray.join('');
  let payloadData = {
    email: window.localStorage.getItem('email'),
    password: window.localStorage.getItem('password'),
    otp: payloadArrayJoin,
    token_name: 'success',
  };
  return apiClient
    .post('auth/login', payloadData)
    .then(response => response.data);
}
